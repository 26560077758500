<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="addPositon"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="onSubmit">
          <div class="modal-header" :style="{ paddingTop: '1rem' }">
            <div class="modal-title">
              {{ labelModal }}
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Parent</label>
              <Select2
                v-model="formData.id_parent"
                :options="optionParent"
                placeholder="Choose Parent"
                class="mr-auto"
                :settings="{
                  allowClear: true,
                  templateResult: resultParent,
                }"
              />
            </div>
            <div class="form-group">
              <label for="jabatan">Kode : </label>
              <input
                type="text"
                class="form-control text-uppercase"
                id="kode"
                v-model="formData.kode"
                v-maska="'XXXXXX'"
                @change="checkKode"
                required
                placeholder="Masukkan Kode Jabatan"
              />
              <div
                v-if="msgCheckKode != '' && msgCheckKode != null"
                class="form-error"
              >
                {{ msgCheckKode }}
              </div>
            </div>
            <div class="form-group">
              <label for="jabatan">Jabatan : </label>
              <input
                type="text"
                class="form-control"
                id="jabatan"
                v-model="formData.nama"
                required
                placeholder="Masukkan Nama Jabatan"
              />
            </div>
            <div class="form-group">
              <label for="status">Status</label>
              <Select2
                v-model="formData.status"
                id="status"
                :options="statusCompany"
                required
                placeholder="Status Jabatan"
                :settings="{ minimumResultsForSearch: -1 }"
                @change="myChangeEvent($event)"
                @select="mySelectEvent($event)"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-save"
              :disabled="
                isSubmit || (msgCheckKode != null && msgCheckKode != '')
              "
            >
              Simpan
            </button>

            <!-- <button type="button" @click="onSync" class="btn btn-blue" :disabled="isSubmit || (msgCheckKode != null && msgCheckKode != '')">
              Simpan & Sync
            </button> -->
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex mb-4 align-items-center">
          <div class="mr-auto">
            <h4 class="title">Jabatan</h4>
          </div>
          <!-- <form class="search mr-3" @submit.prevent="doSearch">
            <input
              type="text"
              id="searchbox"
              v-model="search"
              @keyup="searchCheck"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form> -->
          <!-- <button class="btn-blue mr-2" v-if="enableSync" type="button" @click="getSync" :style="{
              padding: '8px 12px!important',
              borderRadius: '5px!important',
              border: '1px solid #248cf0',
              fontSize: '13px'
            }">
              <img src="/img/icon-sync.svg" alt="" /> Data Sync
            </button> -->
          <button class="btn-add" @click="showModal">+ Tambah</button>
        </div>
        <div class="card-table">
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tablePosition"
            >
              <thead>
                <tr>
                  <th style="width: 45%">Jabatan</th>
                  <th style="width: 15%">Kode</th>
                  <th style="width: 10%">Status</th>
                  <th style="width: 10%" class="text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in listData" :key="index">
                  <td :style="{ paddingLeft: `${ 22 + (10 * getSubLevel(value.sub_level))}px`}">{{ value.nama }}</td>
                  <td class="text-center">{{ value.kode }}</td>
                  <td>
                    <div v-if="value.status == 'aktif'" class="green-bedge">
                      Aktif
                    </div>
                    <div class="red-bedge" v-else>Nonaktif</div>
                  </td>
                  <td class="text-center">
                    <svg
                      class="detail-icon"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Edit"
                      v-on:click="
                        detail(value.id, value.nama, value.kode, value.status, value.id_parent)
                      "
                      width="20"
                      height="20"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5 25.8301H27.125"
                        stroke="#898989"
                        stroke-width="2.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.3125 4.52324C21.8264 4.00938 22.5233 3.7207 23.25 3.7207C23.6098 3.7207 23.9661 3.79158 24.2986 3.92928C24.631 4.06698 24.9331 4.26881 25.1875 4.52324C25.4419 4.77768 25.6438 5.07974 25.7815 5.41217C25.9192 5.74461 25.99 6.10091 25.99 6.46074C25.99 6.82057 25.9192 7.17687 25.7815 7.50931C25.6438 7.84175 25.4419 8.14381 25.1875 8.39824L9.04167 24.5441L3.875 25.8357L5.16667 20.6691L21.3125 4.52324Z"
                        stroke="#898989"
                        stroke-width="2.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

import {
  get_ListPosition,
  get_PositionCheck,
  post_SavePosition,
} from "../../../actions/company/posistion";
import { cksClient, showAlert, showLoading, checkApp } from "../../../helper";
import { post_SyncTable } from "../../../actions/integrations";
import { maska } from "maska";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // TableLite
  },
  directives: {
    maska,
  },
  data() {
    return {
      enableSync: checkApp("rawuh") ? true : false,
      myValue: "aaaa",
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ], // or [{id: key, text: value}, {id: key, text: value}]
      listData: [],
      account: cksClient().get("_account"),
      optionParent: [],
      search: "",
      dataTable: "",
      isSearch: false,
      formData: {
        id: "",
        kode: "",
        id_company: cksClient().get("_account").id_company,
        nama: "",
        id_parent: '',
        status: "",
        isSync: false,
      },
      labelModal: "Tambah Jabatan",
      isSubmit: false,
      msgCheckKode: "",
    };
  },
  mounted() {
    this.getList();

    this.tooltip();
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },
  methods: {
    resultParent(data){
      if (!data.id) {
        return data.text;
      }
      
      var padding = 5 *  this.getSubLevel(data.sub_level)
      return $(`<div style="padding-left: ${padding}px">
        ${data.text}
        </div>`)
    },
    getSubLevel(subLevel){
      if (subLevel) {
        var split = subLevel.toString().split('.')
        if (split.length == 1){
          return 0;
        } else {
          return parseInt(split[split.length - 1])
        }
      }
    },
    checkKode() {
      if (this.formData.kode) {
        this.msgCheckKode = "Tunggu sebentar....";
        get_PositionCheck(
          {
            kode: this.formData.kode.toLowerCase().toUpperCase(),
            id_jabatan: this.formData.id,
          },
          (res) => {
            if (res.is_exist) {
              this.msgCheckKode = "Kode sudah tersedia";
            } else {
              this.msgCheckKode = "";
            }
          },
          () => {
            this.msgCheckKode = "Terjadi kesalahan, silakan ulangi kembali";
          }
        );
      } else {
        this.msgCheckKode = "Inputan ini harus anda isi";
      }
    },
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({
        id,
        text,
      });
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(id, nama, kode, status, id_parent) {
      for (const key in this.optionParent) {
        if (Object.hasOwnProperty.call(this.optionParent, key)) {
          this.optionParent[key].disabled = false;
          if (id == this.optionParent[key].id) {
            this.optionParent[key].disabled = true;
          }
        }
      }
      this.labelModal = "Edit Jabatan";
      this.formData.id = id;
      this.formData.nama = nama;
      this.formData.kode = kode;
      this.formData.status = status;
      this.formData.id_parent = id_parent;
      this.isSubmit = false;
      this.msgCheckKode = "";
      $("#addPositon").modal("show");
      $('[data-toggle="tooltip"]').tooltip("hide");
    },
    searchCheck() {
      if (!this.search && this.isSearch) {
        this.isSearch = false;
        this.getList();
      }
    },
    doSearch() {
      if (this.search || this.isSearch) {
        this.isSearch = true;
        this.getList();
      }
      if (!this.search && this.isSearch) {
        this.isSearch = false;
      }
    },
    getList: async function () {
      await get_ListPosition(
        {
          id_company: this.account.id_company,
          search: this.search,
        },
        (res) => {
          this.listData = [];
          this.listData = res.list;
          this.optionParent = [];
          for (const key in res.list) {
            if (Object.hasOwnProperty.call(res.list, key)) {
              const element = res.list[key];
              this.optionParent.push({
                id: element.id,
                text: element.nama,
                sub_level: element.sub_level,
                disabled: false,
              }) 
            }
          }
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          setTimeout(() => {
            this.tooltip();
            this.dataTable = $("#tablePosition").DataTable({
              info: false,
              order: [],
              paging: false,
            });
          }, 500);
        },
        (err) => {
          console.log(err);
        }
      );
    },
    showModal: function () {
      for (const key in this.optionParent) {
        if (Object.hasOwnProperty.call(this.optionParent, key)) {
          this.optionParent[key].disabled = false;
        }
      }
      this.labelModal = "Tambah Jabatan";
      this.formData.id = "";
      this.formData.nama = "";
      this.formData.kode = "";
      this.formData.id_parent = "";
      this.formData.status = "aktif";
      this.isSubmit = false;
      this.msgCheckKode = "";
      $("#addPositon").modal("show");
    },
    postData() {
      var enableSave = true;
      if (this.msgCheckKode != "" && this.msgCheckKode != null) {
        enableSave = false;
      }
      if (enableSave) {
        this.isSubmit = true;
        this.formData.kode = this.formData.kode.toLowerCase().toUpperCase();
        post_SavePosition(
          this.formData,
          (res) => {
            this.isSubmit = false;
            $("#addPositon").modal("hide");
            var msg =
              res.response_code == 201
                ? "Data jabatan berhasil ditambahkan"
                : "Data jabatan berhasil diperbarui";
            this.getList();
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: msg,
              showCancelButton: false,
            });
          },
          () => {
            $("#addPositon").modal("hide");
            showAlert(this.$swal, {
              title: "GAGAL!",
              msg: "Terjadi Kesalahan, silakan ulangi kembali",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }
        );
      }
    },

    onSubmit() {
      var enableSave = true;
      if (this.msgCheckKode != "" && this.msgCheckKode != null) {
        enableSave = false;
      }
      if (enableSave) {
        this.formData.isSync = false;
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    onSync() {
      var enableSave = true;
      if (this.msgCheckKode != "" && this.msgCheckKode != null) {
        enableSave = false;
      }
      if (
        this.formData.kode != "" &&
        this.formData.nama != "" &&
        this.formData.status != "" &&
        enableSave
      ) {
        this.formData.isSync = true;
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    getSync() {
      var show = showLoading(this.$swal);
      post_SyncTable(
        {
          id_company: cksClient().get("_account").id_company,
          table: "jabatan",
        },
        () => {
          this.getList();
          setTimeout(() => {
            show.close();
            showAlert(this.$swal, {
              title: "SUCCESS!",
              msg: "Data telah disinkronkan",
              confirmButtonText: "Tutup",
            });
          }, 500);
        },
        () => {
          show.close();
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: `Terjadi kesalahan, data tidak dapat disinkronkan`,
            cancelButtonText: "Tutup",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
  },
};
</script>

<style scoped>
button.btn-add {
  border: none !important;
}
.btn-blue {
  padding: 0.3rem 0.8rem;
  border: 0px;
  border-radius: 5px;
  font-size: 12px;
}
</style>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>
